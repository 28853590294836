import * as React from "react"
import SEO from "../components/seo";

const NotFoundPage = () => {
    return (
        <>
            <SEO title={'Not found'} />

            <div className={'page-not-found'}>
                <div className={'container pb-5'}>
                    <h1 className={'mb-1'}>Page not found</h1>
                    <p className={'message'}>Woops. Looks like the page does not exist.</p>
                </div>
            </div>
        </>
    )
}

export default NotFoundPage
