import * as React from "react"
import {graphql} from "gatsby"
import {urlPaths} from "../routes";
import {Breadcrumb, Col, Row} from "react-bootstrap";
import Tags from "../components/blocks/tags";
import NotFoundPage from "../pages/404";

export const query = graphql`
    query ($url: String!) {
        datoCmsProject(url: { eq: $url }) {
            title
            url
            content
            locked
            tools {
                title
            }
            images {
                url
                title
                alt
                fixed(imgixParams: {ar: "16:9", crop: "fit"}) {
                    width
                    src
                    height
                    aspectRatio
                }
            }
        }
    }
`

const ProjectDetail = ({data}) => {
    const {
        datoCmsProject: {
            title,
            content,
            locked,
            images,
            tools
        }
    } = data;

    if (locked) {
        return <NotFoundPage />
    }

    return (
        <div className={'project-details-page'}>
            <title>Projects - {title}</title>
            <div className={'container pb-5'}>
                <div className={'breadcrumbs'}>
                    <Breadcrumb>
                        <Breadcrumb.Item href={urlPaths.project}>Projects</Breadcrumb.Item>
                        <Breadcrumb.Item active>{title}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

                <h2 className={'mt-5'}>{title}</h2>
                <div className={'call-to-action'}>
                    <div className={'call-to-action-wrapper'}>
                        <div className={'content width-three-quarters'} dangerouslySetInnerHTML={{__html: content}}/>
                    </div>
                </div>

                <div className={'tags width-three-quarters'}>
                    <Tags tags={tools} className={'mt-0'} />
                </div>

                <Row className={'mt-5'}>
                    {images?.map((image, index) => {
                        return (
                            <Col md={4} key={index} className={'mb-4'}>
                                <a href={image.url} data-lightbox={index}>
                                    <img src={image.fixed.src} alt={images.alt} height={image.fixed.height}
                                         width={image.fixed.width}/>
                                </a>
                            </Col>
                        )
                    })}
                </Row>
            </div>
            <div className={'gray-bottom'}/>
        </div>
    )
}

export default ProjectDetail