import React from "react";
import {Badge} from "react-bootstrap";
import PropTypes from "prop-types";

const Tags = ({ className, title, tags }) => {
    return (
        <div className={`mt-5 ${className}`}>
            {title && <h2>{title}</h2>}
            {tags?.map((item, idx) => {
                return (
                    <Badge pill bg="light" key={idx}>
                        <span className={'text-black p-2'}>{item.title}</span>
                    </Badge>
                )
            })}
        </div>
    )
}

Tags.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    tags: PropTypes.array,
}

Tags.defaultProps = {
    className: '',
    title: '',
    tags: [],
}

export default Tags